export const menuRoles = {
    itensMenu: {
        solicitacoes: {
            roles: [
                'PORTAL-DE-PROJETOS_SOLICITACOES_DELETAR',
                'PORTAL-DE-PROJETOS_SOLICITACOES_VISUALIZAR',
                'PORTAL-DE-PROJETOS_SOLICITACOES_SALVAR'
            ],
            solicitacoes: {
                visualizar: ['PORTAL-DE-PROJETOS_SOLICITACOES_VISUALIZAR'],
                salvar: ['PORTAL-DE-PROJETOS_SOLICITACOES_SALVAR'],
                deletar: ['PORTAL-DE-PROJETOS_SOLICITACOES_DELETAR']
            }
        },
        parametrizacoes: {
            roles: [
                'PARAMETRIZACAO_AREA-EXECUTORA_VISUALIZAR',
                'PARAMETRIZACAO_AREA-EXECUTORA_SALVAR',
                'PARAMETRIZACAO_AREA-EXECUTORA_DELETAR',
                'PARAMETRIZACAO_CATEGORIA-DO-PROJETO_SALVAR',
                'PARAMETRIZACAO_CATEGORIA-DO-PROJETO_DELETAR',
                'PARAMETRIZACAO_CATEGORIA-DO-PROJETO_VISUALIZAR',
                'PARAMETRIZACAO_TAXAS-PARAMETRIZAVEIS_DELETAR',
                'PARAMETRIZACAO_TAXAS-PARAMETRIZAVEIS_SALVAR',
                'PARAMETRIZACAO_TAXAS-PARAMETRIZAVEIS_VISUALIZAR'
            ],
            areaExecutora: {
                visualizar: ['PARAMETRIZACAO_AREA-EXECUTORA_VISUALIZAR'],
                salvar: ['PARAMETRIZACAO_AREA-EXECUTORA_SALVAR'],
                deletar: ['PARAMETRIZACAO_AREA-EXECUTORA_DELETAR']
            },
            categoriaDeProjeto: {
                visualizar: ['PARAMETRIZACAO_CATEGORIA-DO-PROJETO_VISUALIZAR'],
                salvar: ['PARAMETRIZACAO_CATEGORIA-DO-PROJETO_SALVAR'],
                deletar: ['PARAMETRIZACAO_CATEGORIA-DO-PROJETO_DELETAR']
            },
            taxasParametrizaveis: {
                visualizar: ['PARAMETRIZACAO_TAXAS-PARAMETRIZAVEIS_VISUALIZAR'],
                salvar: ['PARAMETRIZACAO_TAXAS-PARAMETRIZAVEIS_SALVAR'],
                deletar: ['PARAMETRIZACAO_TAXAS-PARAMETRIZAVEIS_DELETAR']
            }
        },
        grupoQualitativo: {
            roles: [
                'PARAMETRIZACAO_AREA-EXECUTORA_VISUALIZAR',
                'GRUPO_QUALITATIVO-CRITERIOS_SALVAR',
                'GRUPO_QUALITATIVO-CRITERIOS_DELETAR',
                'GRUPO_QUALITATIVO-GRUPOS_VISUALIZAR',
                'GRUPO_QUALITATIVO-GRUPOS-SALVAR',
                'GRUPO_QUALITATIVO-GRUPOS-DELETAR'
            ],
            criterios: {
                visualizar: ['PARAMETRIZACAO_AREA-EXECUTORA_VISUALIZAR'],
                salvar: ['GRUPO_QUALITATIVO-CRITERIOS_SALVAR'],
                deletar: ['GRUPO_QUALITATIVO-CRITERIOS_DELETAR']
            },
            grupos: {
                visualizar: ['PARAMETRIZACAO_AREA-EXECUTORA_VISUALIZAR'],
                salvar: ['GRUPO_QUALITATIVO-GRUPOS-SALVAR'],
                deletar: ['GRUPO_QUALITATIVO-GRUPOS-DELETAR']
            },
            questionarioQualitativo: {
                visualizar: ['PARAMETRIZACAO_AREA-EXECUTORA_VISUALIZAR'],
                salvar: ['GRUPO_QUALITATIVO-GRUPOS-SALVAR'],
                deletar: ['GRUPO_QUALITATIVO-GRUPOS-DELETAR']
            }
        }
    }
};

/** Roles personalizadas conforme precisar em alguma aplicação
 * abaixo alguns exemplos
 */
export const rolesPersonalizadas = {};
