import { Component, Inject } from '@angular/core';
import {
    CookieService,
    IMenu,
    LocalStorageService,
    PERMISSOES_SERVICE_TOKEN,
    PermissoesService
} from '@sbt-suite/components';
import { environment } from 'src/environments/environment';
import { menuRoles } from './core/constants/roles';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    MODE = environment.MODE;
    URL_LOGIN = environment.URL_LOGIN;
    URL_LOGOUT = environment.URL_LOGOUT;

    menuItens: IMenu[] = [
        {
            label: '',
            roles: [],
            itens: [
                {
                    label: 'Home',
                    icone: 'home',
                    link: '/home',
                    roles: []
                }
            ]
        },
        {
            label: 'Solicitações',
            roles: menuRoles.itensMenu.solicitacoes.roles,
            itens: [
                {
                    label: 'Solicitações',
                    icone: 'attach_money',
                    link: '/solicitacoes',
                    roles: Object.values(menuRoles.itensMenu.solicitacoes.solicitacoes).flat()
                }
            ]
        },
        {
            label: 'Parametrizações',
            roles: menuRoles.itensMenu.parametrizacoes.roles,
            itens: [
                {
                    label: 'Área Executora',
                    icone: 'settings',
                    link: '/areaexecutora',
                    roles: Object.values(menuRoles.itensMenu.parametrizacoes.areaExecutora).flat()
                },
                {
                    label: 'Categoria de Projeto',
                    icone: 'settings',
                    link: '/categoriaprojeto',
                    roles: Object.values(
                        menuRoles.itensMenu.parametrizacoes.categoriaDeProjeto
                    ).flat()
                },
                {
                    label: 'Taxas Parametrizaveis',
                    icone: 'settings',
                    link: '/taxasparametrizaveis',
                    roles: Object.values(
                        menuRoles.itensMenu.parametrizacoes.taxasParametrizaveis
                    ).flat()
                }
            ]
        },
        {
            label: 'Questionario Qualitativo',
            roles: menuRoles.itensMenu.grupoQualitativo.roles,
            itens: [
                {
                    label: 'Grupos',
                    icone: 'group',
                    link: '/grupo-qualitativo',
                    roles: Object.values(menuRoles.itensMenu.grupoQualitativo.grupos).flat()
                },
                {
                    label: 'Critério',
                    icone: 'groups',
                    link: '/criterio',
                    roles: Object.values(menuRoles.itensMenu.grupoQualitativo.criterios).flat()
                },
                {
                    label: 'Questionário Qualitativo',
                    icone: 'groups',
                    link: '/questionarioqualitativo',
                    roles: Object.values(
                        menuRoles.itensMenu.grupoQualitativo.questionarioQualitativo
                    ).flat()
                }
            ]
        }
    ];

    constructor(
        @Inject(PERMISSOES_SERVICE_TOKEN) private _permissoesService: PermissoesService,
        private _cookie: CookieService,
        private _localStorage: LocalStorageService
    ) {
        if (!environment.USE_BACKEND) {
            _localStorage.setItem('appToken', 'teste');
            _permissoesService.registrarPermissoes(['TESTE_VISUALIZAR']);
        }
        _permissoesService.initPermissoes();
    }

    async logout(event: boolean) {
        if (event) {
            try {
                this.clearCookie();
                this._localStorage.clear();
                window.open(this.URL_LOGOUT, '_self');
            } catch (error) {
                console.error(error);
            }
        }
    }

    private clearCookie() {
        let domain = '';
        if (this.MODE == 'dev' || this.MODE == 'prod') {
            domain = 'tvsbt.com.br';
        }
        this._cookie.delete('access_token', '/', domain);
        this._cookie.delete('refreshToken', '/', domain);
    }
}
